import React from 'react';
import Layout from '../components/layout';

const alcatrazCellBlock = () => (
  <Layout>
    <h2>Alcatraz, Cell Block</h2>
  </Layout>
);

export default alcatrazCellBlock;
